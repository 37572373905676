import React from 'react';
import { Dialog } from 'primereact/dialog';
// import {
//     LBL_SESSION_ENDED
//     , LBL_RENEW_TEXT
//     , LBL_PAYMENT_FAILURE_TEXT
//     , LBL_COOKIES_TEXT
//     , LBL_SOMETHING_WRONG_TEXT
//     , LBL_NEW_VERSION_PUBLISHED_TEXT    
//     , LBL_NEW_VERSION_PUBLISHED
//     , LBL_COOKIE_CONSENT
// } from './../../shared/constants';

/*
     useEffect(() => {        
    
    });
*/

const InfoDialog = (props) => {
    const { 
        header
        , content
        , footer
        , isVisible
        , clsName
        , isModal
        , isResizable
        , isDraggable
        , closeOnEsc
        , isClosable
        , isBlockScroll
        , maskClsName 
        , onHide
    } = props;

    //const [xxx, setXXX] = useState(null);

    return (   
        <div className="standard-dialog">
            <Dialog 
                header={ header }
                footer={ footer }
                visible={ isVisible }                
                className={ clsName }
                modal={ isModal }
                resizable={ isResizable }
                draggable={ isDraggable }
                closeOnEscape={ closeOnEsc }
                closable={ isClosable }
                blockScroll={ isBlockScroll }
                maskClassName={ maskClsName }
                onHide = { onHide }
            >
                <div className={`flex`} style={{alignItems: 'center'}}>
                    <span style={{padding:'1rem'}}>
                        <i className="pi pi-info-circle" style={{fontSize: '3rem', color: '#6fbaed', margin: '2rem 0'}}></i>
                    </span>
                    <span style={{fontSize: '1.2rem', padding:'1.5rem', lineHeight:'25px'}}>
                        {content}
                    </span>
                </div>
            </Dialog>
        </div>         
    );
  }

  export default InfoDialog