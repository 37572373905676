import React, { useState, useEffect, useRef } from 'react';
import { useLocation, Link } from "react-router-dom";
import loadable from '@loadable/component';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Checkbox } from 'primereact/checkbox';
import { OverlayPanel } from 'primereact/overlaypanel';
import DroppButton from './../components/buttons/dropp';
import Logo from '../components/logo-h';
import Terms from './../components/boilerplate/terms';
//import { } from './../shared/options';
import { delay, nanoToUtcDate } from './../shared/fn';
import { APP_NAME_0
        , APP_NAME_1
        , LBL_TERMS_CONDITIONS
        , LBL_GET_ACCESS_TEXT
        // , LBL_REQUIRED_WALLET
        // , LBL_DROPP
        // , LBL_WALLET
        , LBL_PAYMENT_TAG_LINE
        , LBL_SKIP_STEP
        , LBL_USE_OF_SITE
        //, LBL_CHECKING_DROPP_EXTENSION
} from './../shared/constants';
import './_header.scss';

const Countdown = loadable(() => import('./countdown'));

const Header = (props) => {
    const { sessionState, appState, handleSessionExpire, deviceType } = props;
    
    //const [sessionStore] = useSessionStore();
    //const [appStore] = useAppStore();

    const toast = useRef(null);
    const [isDashboard, setIsDashboard] = useState(false);
    const location = useLocation();
    const [dontShowAgain, setDontShowAgain] = useState(false);
    const [lsHideWalletWarning, setLsHideWalletWarning] = useState(false);
    const [merchantId] = useState(process.env.REACT_APP_DROPP_MERCHANT_ID);
    const [showTerms, setShowTerms] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    //const [xxx, setXxx] = useState(false);

    const op = useRef(null);
    const token=sessionState?.userData.token;
    const dateExpires=sessionState?.userData.dateExpires;
    const fee = appState?.appSettings?.find(x => x.settingname==='per_day_fee')?.settingvalue;

    // const checkDroppExtensionInstalled = async () => {
    //     let isInstalled = false;
    //     await delay(2500);
    //     try {
    //         if (window.Dropp && typeof window.Dropp.hasExtensionInstalled === 'function') {
    //             // Call the method to check installation status
    //             isInstalled = await window.Dropp.hasExtensionInstalled();                
    //         }
    //         else {
    //             // Dropp is not installed or hasExtensionInstalled method isn't available
    //             isInstalled=false;
    //         }
    //     } catch (error) {
    //         console.error('Error checking Dropp extension:', error);
    //         isInstalled=false;
    //     }

    //     return isInstalled;
    // };

    useEffect(() => {
        setIsDashboard(location.pathname.toLowerCase() === '/dashboard');

        const hideWarning = localStorage.getItem('__sak:hide-wallet-warning')
        setLsHideWalletWarning(hideWarning);

        const setButtonVisibility = async() => {

            await delay(1000);
            const btn = document.getElementById('GetAccess');

            if(btn) {
                if(!token) {
                    btn.classList.remove('hidden');
                }
                else {
                    btn.classList.add('hidden');
                }
            }
        }

        setButtonVisibility();

     }, [location.pathname, token, appState]);

    const onDontShowAgainClick =(checked)=> {        
        setDontShowAgain(checked)
    }

    const onShowTermsClick = () => {
        setShowTerms(true);
    }

    const closeTermsDialog = () => {
        setShowTerms(false);
    }

    const onDroppButtonClick = async() => {
        localStorage.setItem('__sak:hide-wallet-warning', dontShowAgain);
        setSubmitting(true);
        //await delay(50);
        //op.current.toggle()
    }

    // const clearIframe = async() => {
    //     const iframe = document.getElementById("dropp-base-iframe");
    //     if (iframe) {
    //         iframe.remove();
    //     }
    // }

    const toggleAccessPanel = async(e) => {
        //await clearIframe();
        await delay(50);
        op.current.toggle(e);
    }

    const rightContents = (
        <div>
            {token &&  (                
                <Countdown 
                    token={token} 
                    expireDt={nanoToUtcDate(dateExpires)}
                    handleSessionExpire={handleSessionExpire} 
                />                
            )}

            {!token && (
            <>
                <Button type="button" 
                    id='GetAccess'
                    name='Get Access'
                    icon="pi pi-unlock" 
                    label={`${LBL_GET_ACCESS_TEXT}`}
                    onClick={(e) => toggleAccessPanel(e)} 
                    aria-haspopup aria-controls="overlay_panel" 
                    className={`p-button-outlined p-button login hidden`}
                />

                <OverlayPanel 
                    id="access_panel" 
                    ref={op} 
                    showCloseIcon={false}
                    dismissable={true}             
                    style={{width: '37.5rem'}} 
                    onHide={toggleAccessPanel}
                    className={``}
                    >
                    <div className={`mb-2`} style={{width: '100%', textAlign: 'center', fontSize: '1.25rem'}}>
                        {`${LBL_PAYMENT_TAG_LINE} $${fee}!`}
                    </div>

                    <div className={`${lsHideWalletWarning===true? 'hidden' : '' } mb-2 p-3`} style={{width: '100%', textAlign: 'center', border: '1px solid #555', borderRadius: '.375rem'}}>
                        <div className={`important mb-1`} style={{width: '100%', textAlign: 'center', fontWeight: '500', lineHeight: '1.25rem'}}>
                            * Important: To view Hashpoint on your desktop, both the Dropp mobile app and the Dropp desktop extension MUST be installed.
                            For more information, see the <Link to="https://dropp.cc/resources/quick-start-guide/"
                            target="_blank" 
                            rel="noopener noreferrer" 
                            className='italic'
                            style={{fontSize: '1rem'}}>Dropp Quick Start Guide</Link>
                        </div>
                        <Checkbox id='SkipThisStep' onChange={e => onDontShowAgainClick(e.checked)} checked={dontShowAgain} />
                        <label htmlFor="SkipThisStep" className="p-checkbox-label">&nbsp;&nbsp;{LBL_SKIP_STEP}</label>
                    </div>

                    
                    <div className={`mt-4 mb-2`} style={{width: '100%', textAlign: 'center'}}>
                        { LBL_USE_OF_SITE }&nbsp;<Link onClick={onShowTermsClick} className={`link`}>{ LBL_TERMS_CONDITIONS }</Link>
                    </div>
                    
                    <div className={`flex justify-content-center`} style={{textAlign: 'center'}}>
                        <DroppButton 
                            perDayFee={fee} 
                            merchantId={merchantId} 
                            siteUrl={process.env.REACT_APP_SITE_URL}
                            reference={process.env.REACT_APP_DROPP_INVOICE_REF}
                            onButtonClick={onDroppButtonClick}
                            skipStep={false}
                            deviceType={deviceType}
                        />
                    </div>                    
                </OverlayPanel>
            </>                
            )}
        </div>
    );    

    const centerContents = (        
        <>
            <div style={{}} className={`is-dashboard-${isDashboard} powered-by`}>
                <img src='./powered-by-hgraph.png' alt='powered by hgraph' />
            </div>
        </>        
    );

    const leftContents = (
        <div>
            <Link to="" >
                <div>
                    <div className={`app-name flex`} style={{paddingBottom: deviceType === 'desktop' ? '' : '.5rem'}}>
                        <div><Logo /></div>
                        <div style={{paddingLeft: '1rem'}} className={`app-name-0`}>{APP_NAME_0} {APP_NAME_1}</div>
                    </div>
                    {/* <div className={`${deviceType==='desktop' ? 'hidden' : ''}`} style={{width: '40%', position: 'relative', left: '12rem', top:'-.666rem'}}>{ centerContents }</div> */}
                </div>
            </Link>
        </div>
    );

    return (
        <>
            <header className={`anon`}>
                <Toast ref={ toast } position="bottom-right" />
                <div style={{border: '1px solid transparent' , width:'100%', justifyContent: 'space-between', alignItems: 'center'}} className={`flex flex-wrap`}>
                    <div className={`flex`} style={{width: 'auto', justifyContent: 'left'}}>{ leftContents }</div>
                    <div className={`${deviceType==='desktop' ? '' : 'hidden'}`} style={{width: '10%', justifyContent: ''}}>{ centerContents }</div>
                    <div className={`flex`} style={{width: 'auto', justifyContent: 'right'}}>{ rightContents }</div>
                </div>
            </header>

            <Dialog 
                header={ LBL_TERMS_CONDITIONS }
                visible={showTerms} 
                style={{ width: '60vw' }} 
                onHide={ closeTermsDialog }
            >
                <div><Terms /></div>
            </Dialog>
        </>
    );
};

export default Header;
