import { createStore, createHook } from 'react-sweet-state';

const initialState = {
    userData: {}
    , timeRemaining: 0
};

const actions = {
    setUserData: (data) =>
        ({ setState, getState }) => {
            setState({
                userData: data
            });
        },
        
    setTimeRemaining: (timeRemaining) =>
        ({ setState, getState }) => {
            setState({
                timeRemaining: timeRemaining
            });
        },
   
};

export const sessionStore = createStore({
    initialState,
    actions,
});

// Custom hook to access and update the sessionId
export const useSessionStore = createHook(sessionStore);