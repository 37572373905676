import { useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'

const useInterceptor = (handleExpiredToken) => {
    const navigate = useNavigate();
    useEffect(() => {
        // Create the request interceptor
        // const requestInterceptor = axios.interceptors.request.use(
        //     (config) => {
        //         // Read the cookie with the token
        //         const token = Cookies.get('authToken');

        //         if (token) {
        //             // Attach token to the Authorization header
        //             config.headers['Authorization'] = `Bearer ${token}`;
        //         }

        //         return config;
        //     },
        //     (error) => {
        //         // Handle errors
        //         return Promise.reject(error);
        //     }
        // );

        // Create the response interceptor
        const responseInterceptor = axios.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error.response && error.response.status === 401) {
                    // Handle unauthorized errors (e.g., clear cookies, redirect to login)
                    console.error('Unauthorized! Redirecting to login.');
                    handleExpiredToken();
                    navigate("/", {state: {replace: true }});
                }
                return Promise.reject(error);
            }
        );

        // Cleanup function to remove interceptors when component unmounts
        return () => {
            //axios.interceptors.request.eject(requestInterceptor);
            axios.interceptors.response.eject(responseInterceptor);
        };
    }, []);

    return axios; // Return the axios instance if needed for custom configuration
};

export default useInterceptor;
