import { useEffect, useRef } from 'react';

const useScript = (url) => {
    const beenHere = useRef(false);

    useEffect(() => {
        if(beenHere.current) return;

        const script = document.createElement('script');
        
        console.log(new Date().getTime() + ' - '+ script)
        script.src = url;
        script.async = true;            

        document.body.appendChild(script);

        
        return () => {
            beenHere.current = true;
            document.body.removeChild(script);
        }
    }, [url]);
};

export default useScript;