// App.js
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useQueries } from '@tanstack/react-query';
import { useSessionStore } from './components/stores/useSessionStore';
import { useAppStore } from './components/stores/useAppStore';
import useInterceptor from './components/hooks/useInterceptor';
import { Outlet, useLocation, useSearchParams, useNavigate } from "react-router-dom";
import { emptyCache, delay, uuidv4, emptyGuid, nowToSeconds, nanoToSeconds } from './shared/fn';
import { isMobile } from 'react-device-detect';
import { Splitter, SplitterPanel } from 'primereact/splitter'; 
import { ScrollPanel } from 'primereact/scrollpanel';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import Header from './layout/header';
import Menu from "./layout/menu";
import InfoDialog from './components/dialogs/info';
import WarningDialog from './components/dialogs/warning';
import DroppButton from './components/buttons/dropp';
import AddWallet from './components/forms/add-wallet';

import {
    LBL_SESSION_ENDED
    , LBL_RENEW_TEXT
    , LBL_PAYMENT_FAILURE_TEXT
    , LBL_COOKIES_TEXT
    , LBL_SOMETHING_WRONG_TEXT
    , LBL_NEW_VERSION_PUBLISHED_TEXT    
    , LBL_NEW_VERSION_PUBLISHED
    , LBL_COOKIE_CONSENT
    , LBL_RENEW_SESSION_TEXT
} from './shared/constants';

import * as api from './api/api';
import './styles/scss/app.scss';

const isDevUser=localStorage.getItem('23aa42f6-86bd-438c-96c0-d7d15404eb72');

const getSessionData = async(args) => {
    const t=args.queryKey[1];
    const u=args.queryKey[2];

    const data=await api.getUserSession(t, u);
    console.log('getSessionData', data.data?.response);

    let token
    let dateExpires
    let userId;

    if(data.data) {
        token=data.data?.response[0].sessionid;
        userId=data.data?.response[0].userid;
        dateExpires=data.data?.response[0].dateexpires;
    }
        
    return {token, dateExpires, userId};
}

const getAppSettings = async() => {
    const data=await api.getAppSettings();
    return data;
}

const clearHistory = () => {
    window.history.replaceState({}, '');
    window.history.replaceState(null, '', window.location.pathname);
}

const setUserSettings = (val) => {
    
    if(val === "0") return;

    const theRoot = document.getElementById("root");
    theRoot.addEventListener("contextmenu", function(event) {
        event.preventDefault();
    });
}

const App = () => {
    const [sessionState, { setUserData, setTimeRemaining }] = useSessionStore();
    const [appState, { setAppSettings }] = useAppStore();
    const [callbackResponse, setCallbackResponse] = useState(null);
    const [callbackError, setCallbackError] = useState(null);
    const [showResponseCodeDlg, setShowResponseCodeDlg] = useState(false);
    const [showCookieDlg, setShowCookieDlg] = useState(false);
    const [showRenewDlg, setShowRenewDlg] = useState(false);
    const [showRefreshDlg, setShowRefreshDlg] = useState(false);
    const [showWalletDlg, setShowWalletDlg] = useState(false);
    //const [xxx, setXxx] = useState(null);

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();
    const instance = useInterceptor();
    const [deviceType] = useState(isMobile ? 'mobile' : 'desktop');
    const isAppRoot = location.pathname === '/';
    const pathName = location.pathname.replace('/','');
    const refreshRef = useRef(false);
    const setRef = useRef(false);
    const cookieSetRef = useRef(false);

    let paymentRef = searchParams.get("ref");
    let userId = searchParams.get("userid");
    let code = searchParams.get("code");
    let message = searchParams.get("message");
    let cid = searchParams.get("cid");

    const [appSettingsResponse, userDataResponse] = useQueries({
        queries: [
            {
                queryKey: ['get-app-settings-data'],
                queryFn: getAppSettings,
                staleTime: 86400000,
                refetchInterval:86400000,
                enabled: true,
                // onSuccess: (data) => {
                //     const sessionId = data.sessionId;
                //     if (sessionId) {
                //         //setAppSettings(sessionId);
                //     }
                // },
            },
            {
                queryKey: ['get-session-data', callbackResponse?.data.ref, callbackResponse?.data.userId],
                queryFn: getSessionData,
                staleTime: 86400000,
                refetchInterval:86400000,
                enabled: callbackResponse != null,
            },
        ],
    });
    
    const onCookieAckClick = () => {
        setShowCookieDlg(false);
        localStorage.setItem('__sak:0r4kcTsUHY', new Date().getTime());
    }

    const cookieAckDlgFooter = () => {
        return (
            <div>
                <Button label="Accept" icon="pi pi-check" onClick={() => onCookieAckClick()} autoFocus />
            </div>
        );
    }

    const closeRenewDlg = ()=> {
        setShowRenewDlg(false);
        if(!isAppRoot) {
            navigate("/", {state: {replace: true }});
        }
    }

    const onDroppButtonClick = async() => {
        await delay(50);
        closeRenewDlg();
    }

    const renewDlgFooter = () => {
        const fee = appState.appSettings?.find(x => x.settingname==='per_day_fee')?.settingvalue;

        return (
            <div>
                <DroppButton 
                    perDayFee={fee} 
                    merchantId={process.env.REACT_APP_DROPP_MERCHANT_ID} 
                    siteUrl={process.env.REACT_APP_SITE_URL}
                    reference={process.env.REACT_APP_DROPP_INVOICE_REF}
                    onButtonClick={onDroppButtonClick}
                    buttonText={LBL_RENEW_SESSION_TEXT}
                    deviceType={deviceType}
                />
            </div>
        );
    }

    const onRefreshAckClick =()=> {
        setShowRefreshDlg(false);
        localStorage.setItem('__sak:MVjuSavEw0', false);
    }

    const refreshAckDlgFooter = (name) => {
        return (
            <div>
                <Button label="OK" icon="pi pi-check" onClick={() => onRefreshAckClick()} autoFocus />
            </div>
        );
    }

    const onInvalidCloseClick =()=> {
        setShowResponseCodeDlg(false);
    }

    const handleWalletDialogClose = (e) => { 
        setShowWalletDlg(false);
    }

    const invalidDlgFooter = (name) => {
        return (
            <div>
                <Button label="Close" icon="pi pi-check" onClick={() => onInvalidCloseClick()} autoFocus />
            </div>
        );
    }

    const cookieAck = () => {
        const ack = localStorage.getItem('__sak:0r4kcTsUHY');

        if(!ack) {
            setShowCookieDlg(true);
        }
    }

    const trackEvent = useCallback(async()=> {
        if(process.env.NODE_ENV ==='development') return;

        await delay(1000);
        
        if(!sessionState?.userData?.token) {
            const emptyUuid = emptyGuid();
            api.trackEvent(pathName, sessionState?.userData.token, emptyUuid);
        }
        else {
            const trackUuid = localStorage.getItem('__sak:de5f44x2x') ?? uuidv4();
            localStorage.setItem('__sak:de5f44x2x', trackUuid);
            api.trackEvent(pathName, sessionState?.userData?.token, trackUuid);
        }
    }, [pathName, sessionState?.userData.token]);

    const expireSession = useCallback(async() => {
        setUserData({});
        setTimeRemaining(0);
    }, [setUserData, setTimeRemaining]);
    
    const handleSessionExpire = async(timeLeft) => {
        await expireSession();
        setShowRenewDlg(true);
        await delay(10000);
        navigate("/", {state: {replace: true }});
    }

    const setSessionCookie = useCallback(async(data, remaining) => {
        const cookie = JSON.stringify(data)
        await api.setCookie(process.env.REACT_APP_TOKEN, `${cookie}`, remaining);
        return true;
    }, []);
    
    const refreshUserSession = useCallback(async() => {
        if(refreshRef.current) return;
        refreshRef.current = true;

        try {
            const cookieStr = await api.getCookie(instance, process.env.REACT_APP_TOKEN);
            const token = JSON.parse(cookieStr.data?.cookie);

            if(token.token) {
                const expireDt = nanoToSeconds(token.dateExpires);
                const nowSecs = nowToSeconds();
                const remaining = expireDt - nowSecs;
    
                if(remaining > 0) {
                    await setUserData(token);
                }
                else {
                    await expireSession();
                }
            }
            else {
                if(token.response?.status===400){   //no cookie, session is expired
                    await expireSession();
                    navigate("/", {state: {replace: true }});
                    return;
                }  
            }
        }
        catch (ex) {
    
        }
        
    }, [expireSession, navigate, instance, setUserData]);

    const setUserSession = useCallback(async(userData, remaining) => {
        if(setRef.current) return;        

        try {
            if(userData) {
                await setUserData(userData);
                await setSessionCookie(userData, remaining);
                setTimeRemaining(remaining);
                setRef.current = true;
                navigate("dashboard", {state: {replace: true }});
            }
        }
        catch (ex) {
            console.log('Error:', ex);
        }
        
    }, [setUserData, setSessionCookie, setTimeRemaining, navigate]);

    useEffect(() => {
        if(cookieSetRef.current) return;

        const remaining = appSettingsResponse.data?.find(x => x.settingname==='session_length')?.settingvalue;
        if (userDataResponse.isSuccess) {
            if (userDataResponse.data) {
                setUserSession(userDataResponse.data, parseInt(remaining));
            }
        }
    }, [userDataResponse, appSettingsResponse, setUserData, setUserSession, paymentRef]);

    useEffect(() => {
        if (appSettingsResponse.isSuccess) {
            if (appSettingsResponse.data.length>0) {
                setAppSettings(appSettingsResponse.data);
                const isDisabled=appSettingsResponse.data?.find(x => x.settingname==='disable_right_click')?.settingvalue;
                setUserSettings(isDisabled);
            }
        }
    }, [appSettingsResponse, setAppSettings]);

    useEffect(() => {
        trackEvent();
        cookieAck();

        const response = {"data": {}};
        response.data.ref = paymentRef;
        response.data.userId = userId;
        response.data.code = code;
        response.data.message = message !== "undefined" ? message : null;

        if(paymentRef && userId && code==="0") {
            clearHistory();
            setCallbackResponse(response);
        }
        else if(parseInt(code) < 0 || parseInt(code) > 0) {
            clearHistory();
            setCallbackError(response);
            setShowResponseCodeDlg(true);
        }
        else if(cid) {
            setShowWalletDlg(true);
            clearHistory();
        }
        else {
            refreshUserSession();
        }

    }, [
        refreshUserSession
        , setSessionCookie
        , trackEvent
        , code
        , message
        , paymentRef
        , userId
        , cid
    ]);

    const memoizedSessionState = useMemo(() => sessionState, [sessionState]);
    const memoizedAppState = useMemo(() => appState, [appState]);
    
    if(isDevUser !== 'true' && (window.location.host.includes('hashpoint-dev.netlify.app') || window.location.host.includes('hashpoint.io'))) {
        return <div className={`p-8`}>System upgrade in progress.  We will be back online soon. </div>;
    }

    return (
        <main className={`anon ${(process.env.NODE_ENV ==='development') ? 'dev' : ''} ${deviceType}`}>
            <Splitter style={{height: `100vh`, width: `100vw`}} className={`width-${window.innerWidth} main-splitter`}>
                <SplitterPanel className={`sidebar sidebar-left ${(isAppRoot) ? 'hidden' : ''}`} size={10} minSize={10}>
                    <Menu />
                </SplitterPanel>
                <SplitterPanel size={90} minSize={90} className={`content`}>
                    <Splitter layout="vertical">
                        <SplitterPanel size={10}>
                            <Header 
                                sessionState={ memoizedSessionState }
                                appState={ memoizedAppState }
                                handleSessionExpire={ handleSessionExpire }
                                deviceType={ deviceType }
                            />
                        </SplitterPanel>
                        
                        <SplitterPanel size={90} minSize={90} className={`vertical-container`}>
                            <Splitter>
                                <SplitterPanel
                                    className={``}
                                    size={100}
                                    minSize={100} 
                                    style={{overflowY: 'auto', overflowX: 'hidden'}}
                                >                                    
                                    <ScrollPanel style={{ height: '100vh' }} className={`outlet-wrapper ${isAppRoot ? 'app-root ' : 'route'} `}>
                                        <Outlet />
                                    </ScrollPanel>                                
                                </SplitterPanel>
                            </Splitter>
                        </SplitterPanel>
                    </Splitter>
                </SplitterPanel>
            </Splitter>

            <InfoDialog 
                header={ LBL_COOKIE_CONSENT }
                footer={ cookieAckDlgFooter('showCookieDlg') }
                content={ LBL_COOKIES_TEXT }
                isVisible={ showCookieDlg }
                clsName={`ack-dlg`}
                isModal={ true }
                isResizable={ false }
                isDraggable={ false }
                closeOnEscape={ false }
                isClosable={ false }
                isBlockScroll={ true }
            />

            <InfoDialog 
                header={ LBL_NEW_VERSION_PUBLISHED }
                footer={ refreshAckDlgFooter }
                content={ LBL_NEW_VERSION_PUBLISHED_TEXT }
                isVisible={ showRefreshDlg }
                clsName={`ack-dlg`}
                isModal={ true }
                isResizable={ false }
                isDraggable={ false }
                closeOnEscape={ false }
                isClosable={ false }
                isBlockScroll={ true }
                onHide = { onRefreshAckClick }
            />

            <WarningDialog 
                header={ LBL_SOMETHING_WRONG_TEXT }
                footer={ invalidDlgFooter }
                content= {`${LBL_PAYMENT_FAILURE_TEXT} ${callbackError?.data.code} - ${callbackError?.data.message}`}
                isVisible={ showResponseCodeDlg }
                clsName={`invalid-dlg`}
                isModal={ true }
                isResizable={ false }
                isDraggable={ false }
                closeOnEscape={ false }
                isClosable={ false }
                isBlockScroll={ true }
                onHide = { onInvalidCloseClick }
            />

            <InfoDialog 
                header={ LBL_SESSION_ENDED }
                footer={ renewDlgFooter }
                content={ LBL_RENEW_TEXT }
                isVisible={ showRenewDlg }
                clsName={`renew-dlg`}
                isModal={ true }
                isResizable={ false }
                isDraggable={ false }
                closeOnEscape={ false }
                isClosable={ true }
                isBlockScroll={ true }
                onHide = { closeRenewDlg }
            />

            <Dialog 
                header="Add Dropp Wallet Address"
                visible={showWalletDlg}
                className={`auth-upd-dlg`}
                modal
                resizable={false}
                draggable={false}
                closeOnEscape={false}
                closable={false}
                blockScroll={true}
                maskClassName={``}
                style={{width: '37%'}}
                
            >
                <div className={`flex`}>
                    <AddWallet accessCode={cid} closeDialog={handleWalletDialogClose} />
                </div>
            </Dialog>

        </main>
    );
};

export default App;
