import { createStore, createHook } from 'react-sweet-state';

const initialState = {
    appSettings: [],
    fetchErrorCount: 0,
    message: null
};

const actions = {
        setAppSettings: (data) =>
            ({ setState, getState }) => {
                setState({
                    appSettings: data
                });
            },
        setMessage: (msg) =>
                ({ setState, getState }) => {
                    setState({
                        message: msg
                    });
                },

        fetchStatusChange: (data, count) =>
            ({ setState, getState }) => {
                // mutate sessionState synchronously
                setState({
                    fetchErrorCount: count,
                });
            },
};

export const appStore = createStore({
    initialState,
    actions,
});

// Custom hook to access and update the sessionId
export const useAppStore = createHook(appStore);